<template>
  <div class="text-center">
    <v-dialog
      v-model="open"
      max-width="880px"
      height="327px"
      @click:outside="close"
    >
      <v-card class="global-modalCard3">
        <v-layout fill-height class="pa-0">
          <v-flex
            :class="
              mobile
                ? 'pa-4 text-center pt-10'
                : 'text-left pl-6 pt-10 mt-10 ml-10'
            "
          >
            <p class="global-ptxt">
              <span :class="mobile ? 'text-h5' : 'global-bigtext'"
                >¡Revisa tu correo!</span
              ><br />
              <span :class="mobile ? 'subtitle-1' : 'global-small'"
                >Te enviamos las instrucciones para recuperar<br />
                tu contraseña a
                <span class="font-weight-bold">{{ useremail }}</span></span
              >
            </p>
            <v-spacer v-if="!mobile"></v-spacer>

            <v-btn color="primary" v-if="mobile" @click="close"
              >¡Entendido!</v-btn
            >
          </v-flex>

          <v-flex v-if="TEST" class="text-left pl-6 pt-10 mt-10 ml-10">
            <h1 class="global-titleStyle9">
              su contrasena ha sido cambiada con exito!
            </h1>
            <v-spacer></v-spacer>

            <h4 class="mt-12 global-subtitleStyle5">
              Favor de ingresar nuevamente al sistema <br />
              con la contrasena nueva
            </h4>
          </v-flex>

          <v-flex class="d-flex align-center justify-end" v-if="!mobile">
            <div
              id="ComputerSvg"
              style="align-items: center; justify-content: center"
            >
              <v-flex class="mb-5 text-end justify-end"> </v-flex>
              <v-img
                class="global-image5"
                max-width="160px"
                src="@/assets/modalImages/pana.png"
              />
              <div class="global-buttons my-7 d-flex justify-center">
                <v-btn color="primary" @click="close" height="45" width="153"
                  >¡Entendido!</v-btn
                >
              </div>
            </div>

            <v-img
              max-width="450px"
              width="430"
              id="BackgroundSvgs"
              src="@/assets/modalImages/Vector.png"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    dialog: true,
    TEST: false,
  }),
  props: ["open", "useremail"],
  methods: {
    close() {
      this.$store
        .dispatch("closeDialog")
        .then(this.$router.push({ name: "Login" }));
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
h4 {
  z-index: 10;
}
#BackgroundSvgs {
  position: absolute;
  top: 0%;
  max-width: 450px;
}
#ComputerSvg {
  z-index: 10;
}
::v-deep .v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  /* z-index: inherit; */
  /* box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%); */
}
</style>
