<template>
  <v-app>
    <main>
      <v-container fluid fill-height>
        <v-row class="align-center justify-center">
          <v-col cols="12" md="6" xl="4">
            <div class="d-flex justify-center">
              <v-img
                class="global-logoimg mt-6"
                src="@/assets/logo-mclick-min.png"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-1"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <h4 class="text-h4 my-6 text-center">Recuperación de contraseña</h4>
            <v-card>
              <v-card-title class="headline text-center"
                >¿Olvidaste tu contraseña?</v-card-title
              >
              <v-card-text>
                <v-form ref="recoverpass">
                  <p class="caption">
                    Ingrese el usuario para recuperar la contraseña.
                  </p>
                  <v-text-field
                    label="Usuario"
                    v-model="user"
                    v-mask="rutMask"
                    :rules="rules"
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  large
                  :to="{ name: 'Login' }"
                  :disabled="loading"
                >
                  <v-icon left>mdi-arrow-left</v-icon>
                  {{ mobile ? "" : "Regresar" }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="recover()"
                  large
                  :loading="loading"
                >
                  Recuperar {{ mobile ? "" : "contraseña" }}
                  <v-icon right>mdi-lock-check-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-slide-y-transition>
              <v-alert
                v-model="error"
                type="error"
                outlined
                dismissible
                close
                class="mt-3"
              >
                {{ errormsg }}
              </v-alert>
            </v-slide-y-transition>
          </v-col>
        </v-row>

        <Modal
          :open="usermodal"
          @closeDialogThree="closeDialogThree"
          :useremail="useremail"
        />
      </v-container>
    </main>
  </v-app>
</template>
<script>
import Modal from "@/components/Modal/Modal-Change-Password";
const Urlconvenio = process.env.VUE_APP_CONVENIO;

import { rutMask } from "@/store/resources/masks.js";
import { validarRutFormat, validarRut } from "@/store/resources/validate.js";
import { mClickHeader } from "@/store/resources/headers.js";

import axios from "axios";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      loading: false,
      openthree: false,
      useremail: "",
      error: false,
      errormsg: "",
      user: "",
      rules: [
        (value) => !!value || "Usuario es un campo requerido.",
        (v) => validarRutFormat(v) || "Formato de RUT invalido",
        (v) => validarRut(v) || "No se ha logrado reconocer el RUT",
      ],
    };
  },
  methods: {
    recover() {
      if (this.$refs.recoverpass.validate()) {
        this.loading = true;
        this.error = false;
        axios({
          url: "usuarios/recuperar-clave",
          method: "POST",
          data: {
            usuario: this.user.replace(/[^A-Z0-9]/gi, ""),
            convenio: Urlconvenio,
            modulo: "productos",
          },
          headers: mClickHeader.headers,
        })
          .then((resp) => {
            this.$store.commit("usermodal", true);
            this.useremail = resp.data.data.correo;
          })
          .catch((err) => {
            this.errormsg =
              err.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
              "Ha ocurrido un error al intentar recuperar la contraseña.";
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    closeDialogThree() {
      this.openthree = !this.openthree;
    },
  },
  computed: {
    rutMask() {
      return rutMask;
    },
    usermodal() {
      return this.$store.getters.usermodal;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
